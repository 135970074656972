@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6b7280;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9aa1b1;
}

/* Global styles */
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: white;
  overflow: hidden;
}

.App {
  font-family: "Inter", sans-serif;
}

form {
  padding-top: 80px;
  max-width: 340px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}

/* Input style */
.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input-container:focus-within label {
  transform: translate(0, 12px) scale(0.8);
  color: #0a53e4;
}

.input-container .filled {
  transform: translate(0, 12px) scale(0.8);
}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #6f81a5;
  font-size: 16px;
  line-height: 1;
  left: 16px;
}

.input-container input {
  height: 64px;
  border-radius: 4px;
  border: none;
  padding: 24px 16px 4px 16px;
  font-size: 16px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: black;
}

.input-container input:focus {
  box-shadow: 0 0 0 2px #79b1ff;
}

/* thread box */

.hover-parent .hover-child {
  display: none;
}

.hover-parent:hover .hover-child {
  display: flex;
}

.hover-parent-visib .hover-child-visib {
  visibility: hidden;
}

.hover-parent-visib:hover .hover-child-visib {
  visibility: visible;
}

/* Sidebar collapse button */
.bar {
  background-color: #9ca3af;
  height: 15px;
  width: 3px;
  border-radius: 1rem;
}

.bar-active {
  background-color: #6b7280;
}

.topbar {
  translate: 0 1px;
}

.bottombar {
  translate: 0 -1px;
}
